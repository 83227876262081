@use "styles" as *;

.name {
  height: 100%;
}

.colorSelector {
  width: 80%;
}

.price {
  width: 50%;
}

.button {
  height: 32px;
}

.name,
.colorSelector,
.price,
.button {
  @include rounded-8;
}
