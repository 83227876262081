@use "styles" as *;

.containerCard {
  height: 100%;
  display: flex;
  flex-direction: column;

  &.firstCardIsHighlighted {
    align-items: center;
    text-align: center;

    @include sm-desktop {
      gap: 28px;
      flex-direction: row;
    }
  }
}

.cardImg {
  position: relative;
  aspect-ratio: 1;
  img {
    object-fit: cover;
  }

  &.cardImgHighlighted {
    width: 100%;
    height: 100%;
    @include rounded-12;
    overflow: hidden;
    @include sm-desktop {
      aspect-ratio: 16 / 9;
      @include rounded-16;
      max-width: 50%;
      flex-shrink: 0;
    }
  }
}

.containerText {
  padding: 16px 16px 12px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-grow: 1;

  @include sm-desktop {
    padding: 16px 16px 24px;
  }

  &.containerTextHighlighted {
    align-items: center;
  }
}

.date {
  @include text-xs-semibold-uppercase-cta;
  @include rounded-24;
  @include container-shadow;
  backdrop-filter: blur(10px);
  padding: 4px 10px;
  width: fit-content;
  margin-bottom: 4px;
}

.tag {
  @include text-xs-semibold-uppercase-cta;
  @include color-gradient-light;
  padding: 4px 10px;
  width: fit-content;
  @include rounded-20;
  line-height: 140%;
}

.title {
  margin-bottom: 4px;
  @include text-l-regular;
  &.titleIsBig {
    @include sm-desktop {
      @include text-xl-regular;
    }
  }

  &.titleHighlighted {
    @include text-xxl-regular;
    @include sm-desktop {
      @include text-3xl-regular;
    }
  }
}

.titleTextHighlight {
  margin-bottom: 4px;
  @include text-medium-title;
}

.description {
  margin-bottom: auto;
  @include text-m-regular;
}

.linkAsButton {
  max-width: max-content;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 8px 12px;
}

.textLink {
  text-decoration: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.contentfulTags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  @include text-xxs-regular-uppercase;
}

.contentfulTag {
  display: flex;
  align-items: center;
  gap: 8px;
}

.playIcon {
  @include color-gradient;
  @include rounded-50-percentage;
  position: absolute;
  inset-block-end: 12px;
  inset-inline-end: 8px;
  padding: 10px;
}
