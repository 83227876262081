@use "styles" as *;

.container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: relative;
  padding-bottom: 16px;
  mark {
    font-weight: bold;
    background-color: initial;
    color: inherit;
  }
}

.colorsPanel {
  position: absolute;
  inset: 0;
  z-index: 5;
}

.image {
  position: relative;
  aspect-ratio: 1;
  object-fit: contain;
}

.containerTags {
  justify-content: center;
  height: 1.5rem;
}

.description,
.descriptionAlgolia {
  @include text-3xs-regular;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 22px;
  line-height: 11px;
  padding-inline: 0.5rem;
  opacity: 0.7;
  text-align: center;

  @include sm-desktop {
    @include text-xxs-regular;
  }
}

.description,
.descAlgoliaClamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.name,
.nameAlgolia {
  @include text-s-regular;

  height: 2.2rem;
  padding-inline: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.name,
.nameAlgoliaClamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-transform: capitalize;
  text-overflow: ellipsis;
}

.ratings {
  padding-inline: 0.5rem;
  height: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.price {
  height: 1.5rem;
  padding-inline: 0.5rem;
  align-self: center;
  display: flex;
  align-items: center;
}

.colorSelector {
  height: 1.25rem;
  padding-inline: 0.5rem;
  align-self: center;
  max-width: 100%;
}

.buttonView {
  margin-top: 0.25rem;
  align-self: center;
  padding-inline-start: 12px;
  padding-inline-end: 12px;
}
.button {
  margin-top: 0.5rem;
  align-self: center;
  padding: 0 8px;
  height: 44px;
  display: flex;
}

.actionsPaneContainer {
  position: absolute;
  inset: 0;
  padding: 16px 12px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
  @include glass-gradient;
  @include rounded-16;
  overflow: hidden;
}

.actionsPaneButton {
  display: flex;
  align-items: center;
  gap: 4px;
}

.actionsPaneTitle,
.actionsPaneSubTitle {
  display: block;
  text-decoration: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  text-align: start;
}

.actionsPaneTitle {
  display: inline-block;
  @include text-s-bold;
}

.actionsPaneSubTitle {
  @include text-s-regular;
}

.actionsPanelContent {
  display: flex;
  flex-direction: column;
  gap: 8px;
  @include container-shadow;
  overflow: hidden;
  flex-grow: 1;
  @include glass-gradient();
  border-radius: 12px;
  overflow-y: auto;
  @include hide-scrollbar;
}

.searchImg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.searchShadesAvailables {
  @include text-xs-regular;
  height: 100%;
  padding-inline-end: 2px;
  padding-inline-start: 8px;
  display: flex;
  align-items: flex-start;
  gap: 4px;
  border: 1px solid $light-gray;
  border-radius: 12px;
  > span:first-child {
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
  }
  > span:last-child {
    padding: 1px;
  }
}

.media {
  @include rounded-8;
}
