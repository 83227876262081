@use "styles" as *;

.wrapperAsset {
  position: relative;
  overflow: hidden;
  img {
    object-fit: cover;
  }

  aspect-ratio: var(--editorial-asset-aspect-ratio-mobile);

  @include sm-desktop {
    aspect-ratio: var(--editorial-asset-aspect-ratio-desktop);
  }
}
