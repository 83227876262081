@use "styles" as *;

.wishlist:not(:disabled) {
  display: flex;
  align-items: center;
  padding: 8px;
  color: $dark;
  background: $white;
  flex-shrink: 0;

  @include button-shadow;
  &:focus {
    @include button-shadow;
    color: $dark;
  }
  svg {
    z-index: 1;
  }
  @media (any-hover: hover) {
    &:hover {
      color: $dark;
      @include button-shadow;
      .clipPath {
        background-position-y: 100%;
      }
    }
  }
}

.clipPath {
  clip-path: url(#heart-path);
  // clip-path: path(
  //   "m 0.5351 0.2592 c 0.0065 -0.0107 0.0138 -0.0205 0.0219 -0.0293 c 0.067 -0.0732 0.1753 -0.0732 0.2423 0 c 0.0677 0.074 0.0677 0.1944 0 0.2683 l -0.2993 0.3269 l -0.2993 -0.3268 c -0.0677 -0.074 -0.0677 -0.1944 0 -0.2683 c 0.067 -0.0733 0.1753 -0.0733 0.2423 0 c 0.0081 0.0088 0.0154 0.0185 0.0219 0.0292 l 0.0351 0.0575 l 0.0351 -0.0575 z"
  // );
  width: 16px;
  height: 16px;
  position: absolute;
  background: linear-gradient(0deg, $dark 50%, $white 50%);
  background-size: 100% 200%;
  transition: background-position-y 300ms ease-out;
  &.active {
    background-position-y: 100%;
  }
}
