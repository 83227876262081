@use "styles" as *;

.container {
  height: 100%;
  display: grid;
  padding: 8px;
  grid-template-columns: 1fr 2fr;
  column-gap: 0.5rem;
  position: relative;
  @include rounded-12;
  @include button-shadow;
}

.wrapperProductData {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  overflow: hidden;
}

.colorsPanel {
  position: absolute;
  inset: 0;
  z-index: 5;
}

.image {
  position: relative;
  aspect-ratio: 1;
  align-self: center;
  object-fit: contain;
  width: 100%;
}

.name {
  @include text-s-regular;
  max-height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-transform: capitalize;
  text-overflow: ellipsis;
}

// .ratings {
//   padding-inline: 0.5rem;
//   height: 1rem;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

.price {
  height: 1.5rem;
  display: flex;
  align-items: center;
}

.colorSelector {
  height: 1.25rem;
  max-width: 100%;
}

.button {
  grid-column: span 2;
  margin-top: 0.5rem;
  align-self: flex-end;
}

.actionsPaneContainer {
  position: absolute;
  inset: 0;
  padding: 16px 12px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
  @include glass-gradient;
  @include rounded-16;
  overflow: hidden;
}

.actionsPaneButton {
  display: flex;
  align-items: center;
  gap: 4px;
}

.actionsPaneTitle,
.actionsPaneSubTitle {
  display: block;
  text-decoration: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  text-align: start;
}

.actionsPaneTitle {
  display: inline-block;
  @include text-s-bold;
}

.actionsPaneSubTitle {
  @include text-s-regular;
}

.giftLinkWrapper {
  display: flex;
  text-align: center;
}

.giftLink {
  width: 100%;
}

.label {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  height: 1.5rem;
  align-self: flex-start;
}

p.discount {
  @include text-xxs-bold;
  line-height: unset;
}

del.originalPrice {
  @include text-xxs-regular;
}

.media {
  @include rounded-8;
  aspect-ratio: 1;
}
