@use "styles" as *;

.wrapperSlide {
  padding: 1px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: unset;
  gap: 12px;
}

.wrapperImage {
  position: relative;
  aspect-ratio: 3 / 4;
  @include rounded-16;
  overflow: hidden;

  img {
    object-fit: cover;
  }
}

.slideLink {
  text-align: center;
  @include text-xxs-regular;
  @include text-underline;
  text-underline-offset: 2.5px;
  min-height: 14px;
}

.somethingIsMissing {
  padding: 0 24px;
  @include rounded-16;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  aspect-ratio: 1;
  @include container-shadow-inset;

  @include tablet {
    aspect-ratio: 16 / 9;
  }
}

.firstParagraph {
  display: flex;
  flex-direction: column;
  align-items: center;
  @include text-l-regular;
  .bold {
    @include text-l-semibold;
  }
}

.secondParagraph {
  text-align: center;
  @include text-s-regular;
}
