@use "styles" as *;

.media {
  position: relative;
  @include rounded-16;
  width: 100%;
  height: 100%;
  min-height: 400px;
  overflow: hidden;
  img {
    object-fit: cover;
  }

  &.maxContentMobile {
    aspect-ratio: 1;
    @include sm-desktop {
      aspect-ratio: auto;
    }
  }

  &.maxContentDesktop {
    @include sm-desktop {
      aspect-ratio: 16 / 9;
    }
  }
}
