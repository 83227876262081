@use "styles" as *;

.container {
  --bg-color: transparent;
  padding: 40px 0;
  overflow: hidden;

  &.white {
    --bg-color: #{$white};
    background-color: $white;
  }
  &.pink {
    --bg-color: #{$light-pink};
    background-color: $light-pink;
  }
}

.containerDescription {
  grid-column: span 12;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  @include md-desktop {
    grid-column: 1 / span 4;
    grid-row: 2;
    margin: auto 0;
  }
}

.gridContainer {
  row-gap: 24px;
}

.wrapperTitle {
  grid-column: span 12;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  text-align: center;

  @include md-desktop {
    gap: 4px;
  }
}

.pretitle {
  @include text-s-regular-uppercase;
}

.title {
  @include text-big-title;
}

.outerWrapperDescription {
  @include md-desktop {
    width: 75%;
    @include accordion-open;
  }
}

.wrapperDescription {
  display: grid;
  @include accordion-close;

  &.showMore {
    padding-bottom: 8px;
    @include accordion-open;
  }

  @include md-desktop {
    @include accordion-open;
  }
}

.innerWrapperDescription {
  overflow: hidden;

  @include md-desktop {
    overflow: visible;
  }
}

.readMoreButton {
  margin: 0 auto;
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
  @include text-xxs-regular;
  @include text-underline;

  @include md-desktop {
    display: none;
  }
}

.socialOrMediaWrapper {
  grid-column: span 12;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  @include md-desktop {
    grid-column: 5 / span 4;
    grid-row: 2;
  }
}

.socialIFrameTikTok {
  border: 0;
  overflow: hidden;
  @include rounded-8;
  min-width: 325px;
  min-height: 757px;
  @include color-gradient-light-opacity;
}

.socialIFrameInstagram {
  border: 1px solid $light-gray;
  overflow: hidden;
  @include rounded-8;
  min-width: 325px;
  min-height: 620px;
  @include color-gradient-light-opacity;
}

.media {
  position: relative;
  aspect-ratio: 1;
  width: 100%;
  @include rounded-20;
  overflow: hidden;

  @include tablet {
    width: 50%;
  }
  @include md-desktop {
    width: 100%;
  }

  img {
    object-fit: cover;
    @include color-gradient-light-opacity;
  }
}

.wrapperSwiper {
  width: calc(100% + 16px);
  grid-column: span 12;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @include md-desktop {
    grid-column: 9 / span 4;
    grid-row: 2;
    margin: auto 0;
  }
}

.buttonAddAllToBag {
  width: calc(100% - 16px);
  @include md-desktop {
    width: 60%;
    align-self: center;
  }
}

.socialLink {
  @include text-s-regular;
  @include text-underline;
  display: flex;
  align-items: center;
  gap: 8px;

  &.mobile {
    @include md-desktop {
      display: none;
    }
  }
}

.wrapperLinks {
  grid-column: span 12;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  width: 100%;

  @include md-desktop {
    gap: 24px;
  }
}

.wrapperLinksMobile {
  @include sm-desktop {
    display: none;
  }
}

.wrapperLinksDesktop {
  display: none;
  @include sm-desktop {
    display: flex;
  }
}

.promoBox {
  margin-inline-end: 16px;
  text-align: center;
  @include color-gradient;
  @include rounded-8;

  @include md-desktop {
    margin: 0 calc(18% + 10px);
  }
}

.innerPromoBox {
  padding: 8px;
  margin: 1px;
  border-radius: 7px;
  @include color-gradient-light;
  @include text-xs-regular;
}

.promoSuccess {
  @include kisses-gradient;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.promoError {
  color: $error;
}
