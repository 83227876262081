@use "styles" as *;

.link {
  padding: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;

  @include md-desktop {
    width: 75%;
  }

  svg {
    width: 16px;
    height: 16px;
  }
}

.revieveButton {
  margin-top: 0;
  padding: 16px;
  width: 100%;
  @include text-s-bold-uppercase-cta;

  @include md-desktop {
    width: 75%;
  }

  div,
  svg {
    width: 16px;
    height: 16px;
  }
}

.socialLink {
  @include text-s-regular;
  @include text-underline;
  display: flex;
  align-items: center;
  gap: 8px;

  &.mediumDesktop {
    display: none;
    @include md-desktop {
      display: flex;
    }
  }
}
