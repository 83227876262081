@use "styles" as *;

.spacedButton {
  margin-top: 24px;
  @include sm-desktop {
    align-self: center;
  }
}

.bigButton {
  margin-bottom: 2rem;
}
