@use "styles" as *;

.sidebar {
  display: none;
  --top-filter-space: 24px;

  @include sm-desktop {
    display: flex;
    flex-direction: column;
    gap: 12px;
    grid-row: span 2;
    grid-column: span 3;
    height: fit-content;
    position: sticky;
    top: var(--top-filter-space);

    &.scrollUp {
      top: calc($header-main + $header-nav + var(--top-filter-space));
      transition: top 0.3s ease-in-out;
    }
  }
}

.filterBoxHighlighted {
  background-color: $light-pink;
  @include rounded-8;
  @include sticky-shadow;
  @include text-xs-bold-uppercase;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.filterBoxHighlightedTitle {
  display: flex;
  align-items: center;
  gap: 8px;
}
