@use "styles" as *;

.carouselContainer {
  position: relative;
}

.wrapperSlide {
  padding: 1px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: unset;
  gap: 12px;
}

.arrows {
  margin: 0 -20px;
}

.initialSlide {
  --space-between: 12px;
  --number-slides: 1.2;
  margin-inline-end: var(--space-between);
  width: calc((100% - (var(--space-between) * (var(--number-slides) - 1))) / var(--number-slides));

  @include tablet {
    --number-slides: 2.2;
  }

  @include sm-desktop {
    --number-slides: 3;
  }
}
