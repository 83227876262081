@use "styles" as *;

.mobileAsset {
  @include sm-desktop {
    display: none;
  }
}

.desktopAsset {
  @include mobile-only {
    display: none;
  }
}
