@use "styles" as *;

.panel {
  margin-bottom: 80px;
  text-decoration: none;
  @include sm-desktop {
    margin-bottom: 120px;
  }
}

.video {
  z-index: initial;
}

.panelMarginBottomMobile {
  @include mobile-only {
    margin-bottom: 80px;
  }
}

.panelMarginBottomDesktop {
  @include sm-desktop {
    margin-bottom: 80px;
  }
}

.wrapperSlide {
  position: relative;
  height: 100%;
  aspect-ratio: var(--hero-video-aspect-ratio-mobile);

  @include sm-desktop {
    aspect-ratio: var(--hero-video-aspect-ratio-desktop);
  }
}
