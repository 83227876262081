@use "styles" as *;

.container {
  display: flex;
  align-items: center;
  border-radius: 9999px;
  border: 0.5px solid $gray;
  width: 100%;
  max-width: fit-content;
  overflow: hidden;

  &.removeBorder {
    border: 0;
  }
}

.image {
  border-radius: 9999px;
}

.name {
  margin-inline-start: 0.25rem;
  overflow: hidden;
}

.current {
  @include text-xs-regular;

  display: flex;
  align-items: center;
  padding: 2px;

  border-start-start-radius: 9999px;
  border-end-start-radius: 9999px;
  padding-inline-end: 0.5rem;

  max-width: 100%;
  overflow: hidden;

  p {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}

.available {
  @include text-xs-bold;

  background-color: transparent;

  display: flex;
  align-items: center;
  padding: 2px;

  border-start-end-radius: 9999px;
  border-end-end-radius: 9999px;
  border-inline-start: 0.5px solid $gray;
  padding-inline-start: 0.5rem;
  &.noBtn {
    padding-inline-end: 0.5rem;
  }
}
