@use "styles" as *;

.container {
  position: relative;

  flex: 0 0 100%;
  min-width: 0;
  height: calc(100svh - $header-topbar-mobile - $header-main);
  @include sm-desktop {
    height: calc(100svh - $header-topbar-desktop - $header-main - $header-nav);
  }

  &.contentBelowHeader {
    height: calc(100svh - $header-topbar-mobile);
    @include sm-desktop {
      height: calc(100svh - $header-topbar-desktop);
    }
  }
}

.image {
  position: absolute;
  object-fit: cover;

  &.moveMediaDown {
    object-position: 50% 10%;

    @include sm-desktop {
      object-position: unset;
    }
  }
}

.panel {
  @include white-gradient;

  text-decoration: none;
  grid-column: 1 / span 12;
  padding: 1rem;
  border-radius: 12px;
  margin-bottom: 42px;
  display: flex;
  align-self: flex-end;
  align-items: flex-start;
  flex-direction: column;
  gap: 0.5rem;

  @include sm-desktop {
    grid-column: 1 / span 4;
    margin-bottom: 80px;
  }
}

.centerText {
  text-align: center;
  align-items: center;
}

.centerPanel {
  @include sm-desktop {
    grid-column: 4 / span 6;
  }
}

.panelVariant {
  @include black-gradient;
  color: $white;
}

.title {
  @include text-small-title;
  font-weight: initial;
}

.descriptionWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding-bottom: 4px;
}

.description {
  @include text-m-regular;
}

.h100 {
  height: 100%;
}

.containerCountdown {
  margin: 8px -16px -16px;
  padding: 4px 16px;
  min-height: 24px;
  border-end-end-radius: 12px;
  border-end-start-radius: 12px;
  display: flex;
  gap: 32px;
  align-items: center;
  justify-content: center;
  @include color-gradient;
}

.wrapperCountdown {
  position: relative;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;

  &:not(:last-child)::before {
    content: "";
    position: absolute;
    display: block;
    width: 1px;
    height: 100%;
    background: $dark;
    inset-inline-end: -16px;
  }
}

.labelCountdown,
.numberCountdown {
  @include text-xxs-regular-uppercase;
}

.mediaWrapper {
  position: relative;
  width: 56px;
  height: 56px;
  aspect-ratio: 1;
  img {
    object-fit: cover;
  }
}

.wrapperSlide {
  position: relative;
  height: 100%;
  aspect-ratio: var(--hero-slide-aspect-ratio-mobile);

  @include sm-desktop {
    aspect-ratio: var(--hero-slide-aspect-ratio-desktop);
  }
}

.autoHeightMobile {
  @include mobile-only {
    height: auto !important;
  }
}

.autoHeightDesktop {
  @include sm-desktop {
    height: auto !important;
  }

  .title {
    @include md-desktop {
      @include text-xxl-regular;
      strong {
        @include text-xxl-extralight-uppercase;
      }
    }
  }
}

.panelMarginBottomMobile {
  @include mobile-only {
    margin-bottom: 40px;
  }
}

.panelMarginBottomDesktop {
  @include sm-desktop {
    margin-bottom: 40px;
  }
}
