@use "styles" as *;

.content {
  background: $white;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  @include hide-scrollbar;
  border-radius: 1rem;
  @include container-shadow;
  flex-grow: 1;
  flex-shrink: 0;
  max-height: 100%;

  &:not(.singleColor) {
    padding: 1rem 0;
  }
  &.singleColor {
    border-radius: 0;
  }
}

.color {
  display: flex;
  align-items: center;
  padding: 8px 16px 8px 12px;
  gap: 8px;
  width: 100%;
  background: none;

  transition: background 250ms ease-out, box-shadow 250ms ease-out;
}

.outOfStock {
  .imageContainer::after {
    content: "";
    position: absolute;
    inset: 0;
    display: block;
    margin: auto;
    background: $light-gray;
    height: 1px;
    width: 100%;
    transform-origin: 50% 50%;
    transform: rotate(-45deg);
    transition: background 250ms ease-out;
  }
}

.imageContainer {
  position: relative;
  border: 1px solid $light-gray;
  border-radius: 50%;
  padding: 3px;
  overflow: hidden;
  transition: border-color 250ms ease-out;

  img {
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }
}

.activeColor,
.color:hover {
  background: $white;
  @include container-shadow;

  .imageContainer {
    border-color: $dark;
  }

  &.outOfStock .imageContainer::after {
    background: $dark;
  }
}

.colorName {
  @include text-xs-regular;

  flex: 1;
  text-align: start;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.notifyMe {
  @include text-xs-regular;
}

.price {
  @include text-xs-bold;
}

.changeButton {
  @include text-xs-regular;
  text-decoration: underline;
  margin-inline-end: 0;
  margin-inline-start: auto;
}
