@use "styles" as *;

.wishlistItemsList {
  padding: 0;
  margin: 0;
  list-style-type: none;
  // margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  @include sm-desktop {
    gap: 24px;
  }

  a {
    text-decoration: initial;
  }
}

li.wishlistItem {
  position: relative;
  height: 75px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;

  .wishlistWrapperItem {
    align-self: flex-end;
  }

  .wishlistItemName {
    @include text-s-bold-uppercase;
    text-decoration: initial;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    margin: 0;
    padding: 16px;
    display: flex;
    align-items: flex-start;
  }

  button {
    margin-inline-end: 16px;
    position: relative;
    z-index: 1;
  }
}

.submitModal {
  width: 100%;
  // margin-top: 24px;
}

.createWs {
  width: 100%;
  display: block;
  @include sm-desktop {
    margin: 0 auto;
    max-width: 300px;
  }
}

.option {
  display: flex;
  align-items: center;
  gap: 8px;
}

.optionLabel {
  @include text-s-bold-uppercase;
}

.separator {
  border-style: solid;
  border-color: $light-gray;
  border-top-width: 0;
  border-bottom-width: 0.5px;
  margin: 0;
}

.radioList {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.error {
  @include text-xs-regular;
  color: $error;
  margin-bottom: 16px;
}

// .wishlistName {
//   margin-top: 20px;
// }

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

// modal styles
.wrapper {
  --wrapper-alignItems: flex-start;
  .content {
    padding: 20px 16px;
  }

  @include tablet {
    --wrapper-alignItems: center;
    max-width: 464px;
  }
}
