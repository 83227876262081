@use "styles" as *;

.container {
  padding: 40px 0;
}

.titleEditorialCards {
  @include text-medium-title;
  text-align: center;
  margin-bottom: 40px;

  &.titleEditorialCardsWithFilters {
    @include sm-desktop {
      margin-bottom: 0;
    }
  }
}

.gridCards {
  column-gap: 12px;
  row-gap: 24px;

  @include sm-desktop {
    column-gap: 32px;

    &.gridCardsWithFilters {
      margin-top: 40px;
    }
  }
}

.paragraph {
  margin: 40px auto;
  @include sm-desktop {
    width: 65%;
  }
}

.containerCard {
  overflow: hidden;
  display: block;
  grid-column: span 6;
  @include rounded-12;
  @include container-shadow;
  backdrop-filter: blur(10px);

  @include sm-desktop {
    grid-column: span 3;
    @include rounded-16;
  }

  &.firstCardIsHighlightedWithFilter {
    grid-column: span 12;
    @include sm-desktop {
      grid-column: span 9;
    }
  }

  &.firstCardIsHighlighted {
    grid-column: span 12;
  }
}

.filterBox {
  background-color: $white;
  @include rounded-8;
  @include button-shadow;
  padding: 12px 12px 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.filterBoxName {
  @include text-xs-bold-uppercase;
}

.filterBoxCheck {
  @include text-xs-regular;
  display: flex;
  gap: 4px;
}

.show {
  display: block;
}

.hide {
  display: none;
}

.wrapperClearButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.clearButton {
  @include text-xxs-regular;
  text-decoration: underline;
  text-underline-offset: 2.5px;
}

.highlightValue {
  @include text-xxs-bold-uppercase;
  @include color-gradient;
  height: 1rem;
  min-width: 1rem;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: $dark;
  padding: 0 4.5px;
}
