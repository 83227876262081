@use "styles" as *;

.service {
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100%;
  padding-bottom: 12px;
}

.serviceImg {
  position: relative;
  aspect-ratio: 1;
}

.title {
  @include text-xs-bold-uppercase;
  margin-top: 1rem;
}

.titleHighlighted {
  margin-top: 1rem;
  @include text-xs-regular-uppercase;
  strong {
    @include text-xs-bold-uppercase;
  }
}

.desc {
  display: none;
  @include sm-desktop {
    display: block;
    margin-top: 0.5rem;
    @include text-s-regular;
  }
}

.linkWrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.link {
  margin-top: 1.5rem;
}
