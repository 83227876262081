@use "styles" as *;

.notifyMeContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  gap: 16px;
}

.notifyMeForm {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 16px;
  padding-block-end: 12px;
}

.privacyPolicyLink {
  margin-inline-start: 24px;
}

.alreadySubscribed {
  color: $success;
}

.alreadySubscribedIcon {
  display: inline-block;
  color: $success;
}

.alreadySubscribedTitle {
  @include text-xxs-bold-uppercase;
  margin-bottom: 12px;
  display: inline;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  color: $success;
  vertical-align: 0.5em;
  margin-inline-start: 1em;
}

.alreadySubscribedDescription {
  @include text-xs-regular;
  color: $success;
}

.submitButton {
  width: 100%;
}

.notifyMeEnterEmail {
  @include text-xs-regular;
  // margin-bottom: 10px;
}

.closeButton {
  margin: auto;
}

.closeButtonIcon {
  position: absolute;
  inset-block-start: 12px;
  inset-inline-end: 12px;
  @include sm-desktop {
    inset-block-start: 16px;
    inset-inline-end: 16px;
  }
}

.notifyMeTitile {
  @include text-xxs-bold-uppercase;
}

.newsletterCheckbox {
  @include text-xxs-regular;
}
