@use "styles" as *;

.wrapperDialog {
  --wrapper-alignItems: flex-start;
  --wrapper-maxWidth: 425px;
  @include md-desktop {
    --wrapper-maxWidth: 450px;
  }
}

.panelDialog {
  overflow: hidden;
}

.IFrame {
  border: 0;
  width: 100%;
  height: 85vh;

  @include lg-desktop {
    max-height: 750px;
  }
}
