@use "styles" as *;

.innerImage {
  height: 100%;
  margin: auto;
  @include rounded-8;
}

.ratings {
  width: 50%;
  margin: auto;
  @include rounded-8;
}

.price {
  width: 30%;
  @include rounded-8;
}

.button {
  width: 80%;
  @include rounded-8;
}

.name,
.description {
  @include rounded-8;
  width: calc(100% - 16px);
  margin-inline: auto;
}
