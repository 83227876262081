@use "styles" as *;

div.swiper {
  overflow: initial;
}

div.slide {
  height: auto;
}

div.initialSlide {
  --space-between: 0px;
  --number-slides: 2;
  margin-inline-end: var(--space-between);
  width: calc((100% - (var(--space-between) * (var(--number-slides) - 1))) / var(--number-slides));

  @include tablet {
    --space-between: 12px;
    --number-slides: 3;
  }
  @include sm-desktop {
    --number-slides: 4;
  }
  @include md-desktop {
    --number-slides: 5;
  }
}

div.preloadGrid {
  grid-template-columns: repeat(5, 1fr);
}
